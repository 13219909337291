// Launch Fast on page opening
document.addEventListener('turbo:load', async () => {
  if (window.location.hash.includes('#open-fast') || window.location.pathname === '/fonctionnement-codeur') {
    const { fastApp } = await import('@/apps/fast/main.js')
    await fastApp

    window.FastApp.launch(window.location.search)
    $('.hiw-tmp-overlay').fadeOut()
  }
})
