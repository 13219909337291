/* global App */

window.App.charactersCounter = {}
;(function () {
  this.init = () => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        Array.from(mutation.addedNodes).forEach(element => {
          if (element instanceof HTMLElement) this.applyOn(element)
        })
      })
    })

    this.applyOn(document)
    observer.observe(document, { subtree: true, childList: true })
  }

  this.applyOn = element => {
    const inputs = element.querySelectorAll('[data-characters-counter-target]')

    const setCount = e => {
      const targetEl = document.querySelector(e.target.dataset.charactersCounterTarget)
      // * Work around to normalize Ruby and JS characters counting methods: JSON.stringify renders '\n' for new lines, which is 2 characters long, and as Rails converts new lines to "carriage return line feed" ( \r\n which is 2 characters ) on form submission, both front and back end gets the same counting result
      const countValue = e.target.value.length > 0 ? JSON.stringify(e.target.value.trim()).length - 2 : 0
      const maxValue = e.target.dataset.charactersCounterMax

      targetEl.innerHTML = countValue.toLocaleString()

      countValue > maxValue
        ? targetEl.parentNode.classList.add('text-danger')
        : targetEl.parentNode.classList.remove('text-danger')
    }

    inputs.forEach(input => {
      input.addEventListener('keyup', setCount)
      input.dispatchEvent(new Event('keyup'))
    })
  }
}).apply(App.charactersCounter)
