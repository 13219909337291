$.fn.effect = function (name = 'highlight', options, done) {
  const element = $(this)
  const backgoundColor = { backgroundColor: element.css('backgroundColor') }
  element.css({
    backgroundImage: 'none',
    backgroundColor: options.color || '#ffff99'
  })

  setTimeout(() => element.css(backgoundColor), done)
}
