/* global App Rails */

window.App.project = {}
;(function () {
  this.start = function () {
    $(document).on('click', '.contact-block-toggle', this.toggleContactBlock)
  }

  this.toggleContactBlock = function (event) {
    event.preventDefault()
    $(this).blur()
    const parent = $(this).closest('.card')
    parent.find('.card-contact-block').slideToggle()
    if (typeof $(this).data('track-contact-info-seen') !== 'undefined') {
      const $link = $(this)
      Rails.ajax({
        url: $(this).data('track-contact-info-seen'),
        type: 'GET', // FIXME: Should be a PATCH/PUT
        success() {
          $link.removeData('track-contact-info-seen')
          $link.removeAttr('data-track-contact-info-seen')
        }
      })
    }
  }
}).apply(App.project)
