/* global App Rails */

import { post } from '@rails/request.js'

window.App.auth = {}
;(function () {
  this.init = function () {
    this.registrationFormSelector = '#new_user'
    this.userTypologySelector = '[name="user[typology]"]'

    if ($(this.registrationFormSelector).length > 0) {
      this.initRegistration()
    }
  }

  this.initRegistration = function () {
    const self = this
    $(self.registrationFormSelector).on('change', self.userTypologySelector, function (event) {
      if ($(this).val() === '') {
        $('.user-typology-required').hide()
      } else {
        $('.user-typology-required').show()
      }
    })
  }

  this.checkUser = function (email, processResponse, handleError) {
    post('/projects/check-user', { body: { email, responseKind: 'json' } }).then(response => {
      if (response.ok) {
        processResponse(response.json)
      } else {
        handleError(response.json, response.statusCode, response)
      }
    })
  }
}).apply(App.auth)
