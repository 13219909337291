/* global App */

window.App.menuOverflow = {}
;(function () {
  this.init = function () {
    window.addEventListener('resize', event => {
      window.App.menuOverflow.updateAll()
    })
    window.App.menuOverflow.updateAll()
  }

  this.updateAll = function (event) {
    $('*[data-menu-overflow]').each(function (index, element) {
      window.App.menuOverflow.update($(this))
    })
  }

  this.initNav = function (nav) {
    if (typeof nav.prop('list') !== 'undefined') {
      return
    }

    const list = []
    let allItemsWidth = 0
    nav.children().each((i, a) => {
      const width = $(a).width() + 1
      allItemsWidth += width
      list.unshift({
        tag: $(a),
        width,
        end: allItemsWidth,
        active: $(a).hasClass('active')
      })
    })
    const button = $(
      '<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"></a>'
    ).html(nav.data('menu-overflow'))
    const dropdownList = $('<div class="dropdown-menu dropdown-menu-right"></div>')
    const dropdown = $('<li>').addClass('nav-item nav-more dropdown').append(button).append(dropdownList).css({
      visibility: 'hidden'
    })
    nav.append(dropdown)
    nav.prop('list', list)
    nav.prop('allItemsWidth', allItemsWidth)
    nav.prop('dropdown', dropdown)
    nav.prop('dropdownWidth', dropdown.width())
    dropdown.css({
      display: 'none',
      visibility: 'visible'
    })
    nav.css({
      overflow: 'visible'
    })
  }

  this.update = function (nav) {
    window.App.menuOverflow.initNav(nav)
    const list = nav.prop('list')
    const allItemsWidth = nav.prop('allItemsWidth')
    const dropdown = nav.prop('dropdown')
    const dropdownWidth = nav.prop('dropdownWidth')
    const visibleWidth = nav.width() - 10

    if (allItemsWidth <= visibleWidth) {
      dropdown.hide()
      list.forEach(item => {
        item.tag.show()
      })
    } else {
      let activeWidth = 0
      const hiddens = []
      list.forEach(item => {
        if (item.active) {
          item.tag.show()
          activeWidth = item.width
        } else if (item.end + activeWidth + dropdownWidth <= visibleWidth) {
          item.tag.show()
        } else {
          item.tag.hide()
          hiddens.unshift(item)
        }
      })
      window.App.menuOverflow.generateDropdownMenu(dropdown, hiddens)
      dropdown.show()
    }
  }

  this.generateDropdownMenu = function (dropdown, links) {
    const menu = dropdown.find('.dropdown-menu')
    menu.empty()
    links.forEach(item => {
      menu.append(item.tag.find('.nav-link').clone().addClass('dropdown-item').removeClass('nav-link'))
    })
  }
}).apply(App.menuOverflow)
