/* global App */

window.App.notifications = {}
;(function () {
  this.hideTimeout = null
  this.nextShowTimeout = null
  this.notificationTemplate =
    '<div class="notification notification-{{level}}" data-auto-dismiss="true" role="alert">' +
    '<div class="notification-content">' +
    '<div class="notification-text"><p>{{message}}</p></div>' +
    '<button class="notification-close" type="button">Fermer</button>' +
    '</div>' +
    '</div>'

  this.init = function () {
    let started = false

    $('.notification').each((index, element) => {
      const $element = $(element)

      $element.attr('data-shown', 'false')

      if (!started) {
        started = true
        App.notifications.show($element)
      }
    })
  }

  this.destroy = function () {
    $('.notification.visible').removeClass('visible')
    App.notifications.clearTimeouts()
  }

  this.clearTimeouts = function () {
    if (App.notifications.hideTimeout) {
      clearTimeout(App.notifications.hideTimeout)
    }
    if (App.notifications.nextShowTimeout) {
      clearTimeout(App.notifications.nextShowTimeout)
    }
  }

  this.show = function ($element) {
    $element.addClass('visible')
    $element.find('.notification-close').on('click', App.notifications.close)

    if ($element.data('autoDismiss')) {
      App.notifications.hideTimeout = setTimeout(App.notifications.hide, 6000, $element)
    }
  }

  this.showNext = function ($prevElement, $nextElement) {
    $prevElement.removeClass('hidden')
    App.notifications.show($nextElement)
  }

  this.hide = function ($element) {
    $element.addClass('hidden').removeClass('visible').attr('data-shown', 'true')
    $element.find('.notification-close').off('click')

    const $nextElement = $('.notification[data-shown="false"]').first()

    if ($nextElement) {
      App.notifications.nextShowTimeout = setTimeout(App.notifications.showNext, 600, $element, $nextElement)
    }
  }

  this.close = function (event) {
    event.preventDefault()
    App.notifications.clearTimeouts()
    App.notifications.hide($(this).parents('.notification'))
    return false
  }

  this.flash = function (level, message) {
    const self = this
    level = level === 'error' ? 'danger' : level

    const $notification = $(self.notificationTemplate.replace('{{level}}', level).replace('{{message}}', message))
    $('.notifications').append($notification)
    self.show($notification)
  }
}).apply(App.notifications)
