/* global App Turbo */

window.App.orders = {}

// This module permits to redirect to category page when a sponsored profile
// payment attempt expires.
;(function () {
  this.config = {
    expiresAtSelector: '#spea',
    expirationUrlSelector: '#spcu',
    checkInterval: 10000
  }

  this.init = function () {
    if ($(this.config.expiresAtSelector).length === 0) {
      return false
    }

    this.expiresAt = parseInt($(this.config.expiresAtSelector).val(), 10) - this.config.checkInterval
    this.expirationUrl = $(this.config.expirationUrlSelector).val()
    this.checkExpiration()
  }

  this.checkExpiration = function () {
    if (this.expiresAt < new Date().getTime()) {
      Turbo.visit(this.expirationUrl)
    } else {
      setTimeout(() => this.checkExpiration(), this.config.checkInterval)
    }
  }
}).apply(App.orders)
