/* global App */

window.App.userConnection = {}
;(function () {
  this.init = function () {
    if ($('.edit_user #user_name').length === 0) {
      return false
    }

    $('.edit_user #user_name').keyup(function () {
      const originalName = $('#original_name').text()
      const newName = $(this).val()

      if (originalName !== newName) {
        $('.edit_user .js-name-alert').slideDown()
      } else {
        $('.edit_user .js-name-alert').slideUp()
      }
    })

    $('.edit_user #user_name').keyup()
  }
}).apply(App.userConnection)
