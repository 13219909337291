import { get } from '@rails/request.js'

function waitForPopupClosedAndExecute(popup, origin) {
  const pollTimer = window.setInterval(() => {
    try {
      if (popup.closed) {
        window.clearInterval(pollTimer)
        afterAuthRedirect(origin)
      }
    } catch (e) {
      // FIXME: Why an empty catch here?
    }
  }, 100)
}

function afterAuthRedirect(origin) {
  get(`/users/after-auth-redirection.json?origin=${origin}`)
    .then(response => response.json)
    .then(data => {
      if (data.success) {
        window.location.href = data.redirect_url
      } else {
        window.location.reload()
      }
    })
}

document.addEventListener('turbo:load', () => {
  let socialPopup

  $('.social-popup').click(function (event) {
    const width = 575

    const height = 500

    const left = ($(window).width() - width) / 2

    const top = ($(window).height() - height) / 2

    const url = this.href

    const opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left

    socialPopup = window.open(url, 'sharing-modal', opts)

    return false
  })

  // Social signin buttons
  $('.social-signin-button').click(function (event) {
    waitForPopupClosedAndExecute(socialPopup, $(this).data('origin'))
  })
})
