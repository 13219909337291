document.addEventListener('turbo:load', () => {
  document
    .querySelectorAll('.form-group.offer_rule_keywords input.search, .form-group.offer_rule_keywords_forbidden input.search')
    .forEach(el => {
      el.addEventListener('blur', function () {
        const parent = el.parentNode
        this.value = ''
        if (parent.querySelectorAll('.ui.label').length === 0) {
          parent.querySelector('div.default.text').classList.remove('filtered')
        }
      })
    })
})
