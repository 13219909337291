/* global App */

window.App.selectDropdown = {}
;(function () {
  this.init = function () {
    const self = this
    self.dropdownSelector = '.select-dropdown'

    $(document).on('click', '.select-dropdown .dropdown-item', function (event) {
      event.preventDefault()
      const val = $(this).data('value')
      const selectDropdown = $(this).parents(self.dropdownSelector)
      selectDropdown.find('.dropdown-toggle').html(val)
      selectDropdown.find('input[type="hidden"]').val(val)
    })
  }
}).apply(App.selectDropdown)
