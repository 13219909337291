/* global App */

window.App.formHelper = {}
;(function () {
  const config = {
    groupSelector: '.form-group',
    dataHelpSelector: 'hint',
    inputSelector: '.form-control',
    helpSelector: '[data-hint]',
    helperSelector: '.form-helper'
  }
  this.timer = null

  this.init = function () {
    const self = this
    if ($('[data-hint]').length <= 0) {
      return false
    }
    // $('body').append('<div class="form-helper alert"></div>');
    $(config.helpSelector).on({
      mouseenter(event) {
        self.buildTooltip(this)
      },

      mouseleave(event) {
        self.timer = setTimeout(() => {
          App.formHelper.destroyTooltip()
        }, 300)
        if ($(config.inputSelector + ':focus').length > 0) {
          $(config.inputSelector + ':focus').trigger('focus')
        }
      },
      focus(event) {
        self.buildTooltip($(this))
      },
      blur(event) {
        self.timer = setTimeout(() => {
          self.destroyTooltip()
        }, 300)
      }
    })
  }

  this.buildTooltip = function (element) {
    const self = this

    if (self.timer !== null) {
      clearTimeout(self.timer)
    }
    const $element = $(element)
    const text = $element.data('hint')
    if (text === null) {
      return false
    }
    $(config.helperSelector).html(text)

    $('.help-human .content-inner').css({ opacity: 0 })
    $(config.helperSelector).addClass('visible')
  }

  this.destroyTooltip = function () {
    $(config.helperSelector).removeClass('visible')
    $('.help-human .content-inner').css({ opacity: 1 })
  }
}).apply(App.formHelper)
