import 'bootstrap/js/src/modal'

window.App.modal = {
  visible: false,
  afterCloseURL: null,

  get $element() {
    return $('#codeur-modal-dialog')
  },

  get element() {
    return document.querySelector('#codeur-modal-dialog')
  },

  get dialogElement() {
    return this.$element.find('.modal-dialog')
  },

  get headerElement() {
    return this.$element.find('.modal-header')
  },

  get footerElement() {
    return this.$element.find('.modal-footer')
  },

  get titleElement() {
    return this.$element.find('.modal-title')
  },

  get bodyElement() {
    return this.$element.find('.modal-body')
  },

  // function setModalTitle(title) {
  set title(value) {
    if (this.headerElement.length <= 0) {
      this.$element.prepend(
        '<div class="modal-header">' +
          '<button aria-hidden="true" class="close" ' +
          'data-dismiss="modal" type="button">×</button>' +
          '<h3 class="modal-title"></h3></div>'
      )
    }

    this.titleElement.html(value)
  },

  set contentClass(className) {
    if (className === null) {
      this.bodyElement.attr('class', 'modal-body')
    } else {
      this.bodyElement.addClass(className)
    }
  },

  // function setModalContent(content, className) {
  set content(html) {
    this.bodyElement.html(html)
  },

  // function setModalOptions(options){
  set options(object) {
    if (typeof object === 'undefined') {
      return false
    }
    if (object.afterCloseURL) {
      this.afterCloseURL = object.afterCloseURL
    }
    if (object.class) {
      this.dialogElement.addClass(object.class)
    }
    if (object.styles) {
      this.dialogElement.css(object.styles)
    }
    if (object.noFooter || object.footer === false) {
      this.footerElement.hide()
    }
    if (object.noHeader || object.header === false) {
      this.headerElement.hide()
    }
    if (object.backdropStatic) {
      try {
        this.$element.data('bs.modal')._config.backdrop = 'static'
      } catch (err) {
        this.$element.attr('data-backdrop', 'static')
      }
    }
  },

  init() {
    this.$element.on('shown.bs.modal', event => {
      this.visible = true
    })

    this.$element.on('hidden.bs.modal', event => {
      if (this.afterCloseURL !== null && typeof this.afterCloseURL !== 'undefined') {
        window.location.href = this.afterCloseURL
      } else {
        this.visible = false
        document.querySelector('body').classList.remove('overflow-hidden')
        this.reset()
      }
    })

    $('.show-modal').click(event => {
      event.preventDefault()
      const data = $(event.target).data()
      this.show(data.modalTitle, $(data.modalContent).html())
    })
  },

  // Show modal with given parameters
  show(title, content, options) {
    this.reset()
    this.title = title
    this.content = content
    this.options = options
    this.$element.modal('show')
    this.visible = true
    setTimeout(() => {
      this.element.classList.add('overflow-auto')
    }, 250)
    document.querySelector('body').classList.add('overflow-hidden')
  },

  // Close current modal. Returns false if no visible modal.
  close() {
    if (this.visible) {
      this.$element.modal('hide')
      this.visible = false
      return true
    }
    return false
  },

  // function resetModal(){
  reset() {
    this.title = ''
    this.content = ''
    this.contentClass = null
    this.options = {}
    this.dialogElement.attr('class', 'modal-dialog')
    this.headerElement.show()
    this.footerElement.show()
  }
}
