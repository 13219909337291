/* global Rails */

// From https://kirillplatonov.com/2019/04/26/prevent_opening_rails_remote_links_in_new_tab/

document.addEventListener('turbo:load', () => {
  const invalidLinks = Array.from(document.querySelectorAll("a[data-remote='true'], a[data-method]"))
  const jsVoid = 'javascript:void(0);'
  invalidLinks.forEach(element => {
    if (element.href !== jsVoid) element.dataset.url = element.href
    // Firefox still open new tab, but with a blank page. Better than before...
    element.href = jsVoid
  })
  Rails.href = function (element) {
    return element.dataset.url || element.href
  }
})
