/* global App */

window.App.accordion = {}
;(function () {
  this.init = function () {
    document.querySelectorAll('.accordion[data-auto-expand="true"]').forEach(accordion => {
      const visibleTabPanel = accordion.querySelector('.collapse.show')
      if (visibleTabPanel === null) {
        accordion.querySelector('.card-header-tab').click()
      }
    })
  }
}).apply(App.accordion)
