/* global App */

window.App.selectFile = {}
;(function () {
  this.start = function () {
    const self = this
    self.fileSelector = '.select-file'

    $(document).on('click', '.select-file button.choose-file, .select-file button.change-file', function (event) {
      event.preventDefault()
      const selectFile = $(this).parents(self.fileSelector)
      selectFile.find('input[type="file"]').click()
    })

    $(document).on('click', '.select-file button.remove-file', function (event) {
      event.preventDefault()
      const selectFile = $(this).parents(self.fileSelector)
      selectFile.find('input[type="file"]').val('')
      selectFile.find('input[type="file"]')[0].dispatchEvent(new Event('change'))
      selectFile.find('input[disabled="disabled"]').val('')
      selectFile.find('.choose-file').show()
      selectFile.find('.change-file').hide()
      selectFile.find('.remove-file').hide()
    })

    $(document).on('change', '.select-file input[type="file"]', function (event) {
      const selectFile = $(this).parents(self.fileSelector)
      if (event.target.files[0] === null) return

      const fileName = event.target.files[0].name

      selectFile.find('input[disabled="disabled"]').val(fileName)
      selectFile.find('.choose-file').hide()
      selectFile.find('.change-file').show()
      selectFile.find('.remove-file').show()
    })

    $(document).on('change', '#project_details_attributes_0_file', event => {
      event.preventDefault()
      event.stopPropagation()
      $('.dropzone')
        .find('.dropzone-files')
        .html('<i class="far fa-file"></i> ' + event.target.files[0].name)
    })
  }

  this.init = function () {
    $('.dropzone').on({
      click(event) {
        $('#project_details_attributes_0_file').click()
      },
      dragenter(event) {
        event.preventDefault()
        $(this).addClass('drag-over')
      },
      dragleave(event) {
        event.preventDefault()
        $(this).removeClass('drag-over')
      },
      dragover(event) {
        event.preventDefault()
        event.originalEvent.dataTransfer.dropEffect = 'copy'
      },
      drop(event) {
        event.preventDefault()
        $(this).removeClass('drag-over')
        const files = event.originalEvent.dataTransfer.files
        if (files.length > 1) {
          App.notifications.flash('danger', "Vous ne pouvez pas sélectionner plus d'un fichier.")
          return false
        }
        $('#project_details_attributes_0_file').prop('files', files)
        $(this)
          .find('.dropzone-files')
          .html('<i class="far fa-file"></i> ' + files[0].name)
      }
    })
  }
}).apply(App.selectFile)
